import { BigNumber } from "@ethersproject/bignumber";
import { t } from "@lingui/macro";
import { CustomUserProperties, SwapEventName } from "@uniswap/analytics-events";
import { CurrencyAmount, Percent, TradeType } from "@uniswap/sdk-core";
import {
  FlatFeeOptions,
  SwapRouter as SwapRouter2,
  UniswapTrade,
} from "@uniswap/universal-router-sdk";
import { SwapRouter } from "@uniswap/v3-sdk";

import { FeeOptions, toHex } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import { sendAnalyticsEvent, useTrace } from "analytics";
import { useCachedPortfolioBalancesQuery } from "components/PrefetchBalancesWrapper/PrefetchBalancesWrapper";
import { getConnection } from "connection";
import useBlockNumber from "lib/hooks/useBlockNumber";
import {
  formatCommonPropertiesForTrade,
  formatSwapSignedAnalyticsEventProperties,
} from "lib/utils/analytics";
import { useCallback } from "react";
import { ClassicTrade, TradeFillType } from "state/routing/types";
import { useUserSlippageTolerance } from "state/user/hooks";
import { trace } from "tracing/trace";
import { calculateGasMargin } from "utils/calculateGasMargin";
import { UserRejectedRequestError, WrongChainError } from "utils/errors";
import isZero from "utils/isZero";
import {
  didUserReject,
  swapErrorToUserReadableMessage,
} from "utils/swapErrorToUserReadableMessage";
import { getWalletMeta } from "utils/walletMeta";

import { PermitSignature } from "./usePermitAllowance";
import { universalRouterAddressHelper } from "graphql/data/nft/NftUniversalRouterAddress";
import { AbiCoder } from "ethers/lib/utils";
import { generateLsp7Contract } from "components/PrefetchBalancesWrapper/fetchLSP7Assets";
import { ethers } from "ethers";
import lsp7Abi from "../abis/lsp7.json";
import JSBI from "jsbi";
import { useUPProvider } from "./useUPProvider";

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`);
  }
}

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    );
  }
}

interface SwapOptions {
  slippageTolerance: Percent;
  deadline?: BigNumber;
  permit?: PermitSignature;
  feeOptions?: FeeOptions;
  flatFeeOptions?: FlatFeeOptions;
}

export function useUniversalRouterSwapCallback(
  trade: ClassicTrade | undefined,
  fiatValues: { amountIn?: number; amountOut?: number; feeUsd?: number },
  options: SwapOptions
) {
  const { account, chainId, provider, connector } = useWeb3React();
  const analyticsContext = useTrace();
  const blockNumber = useBlockNumber();
  const isAutoSlippage = useUserSlippageTolerance()[0] === "auto";
  const { data } = useCachedPortfolioBalancesQuery({ account });
  const portfolioBalanceUsd =
    data?.portfolios?.[0]?.tokensTotalDenominatedValue?.value;

  const { gridOwnerAccount } = useUPProvider();


  return useCallback(async () => {
    return trace(
      "swap.send",
      async ({ setTraceData, setTraceStatus, setTraceError }) => {
        try {
          if (!account) throw new Error("missing account");
          if (!chainId) throw new Error("missing chainId");
          if (!provider) throw new Error("missing provider");
          if (!trade) throw new Error("missing trade");
          const connectedChainId = await provider.getSigner().getChainId();
          if (chainId !== connectedChainId) throw new WrongChainError();

          setTraceData(
            "slippageTolerance",
            options.slippageTolerance.toFixed(2)
          );
          // const { calldata: data, value } = SwapRouter.swapCallParameters(
          //   trade,
          //   {
          //     slippageTolerance: options.slippageTolerance,
          //     deadlineOrPreviousBlockhash: options.deadline?.toString(),
          //     inputTokenPermit: options.permit,
          //     fee: options.feeOptions,
          //     flatFee: options.flatFeeOptions,
          //   }
          // );

          let { calldata: data, value } = SwapRouter2.swapERC20CallParameters(
            trade, // Assuming 'trade' is an instance of ClassicTrade or Trade
            {
              slippageTolerance: options.slippageTolerance,
              deadlineOrPreviousBlockhash: options.deadline?.toString(),
              inputTokenPermit: options.permit,
              fee: options.feeOptions,
              // flatFee: options.flatFeeOptions,
            }
          );

          const tradeHelper: UniswapTrade = new UniswapTrade(trade, options);


          const isNativeToken = trade.routes[0].input.isNative;


          // add 0x05b70a7c46f91909bf691f2e and address of grid owner account to the data if grid owner account is set
          if (gridOwnerAccount && !isNativeToken) {
            data = data + "05b70a7c46f91909bf691f2e" + gridOwnerAccount.slice(2);
          } else if (!gridOwnerAccount && !isNativeToken) {
            data = data + "05b70a7c46f91909bf691f2e0000000000000000000000000000000000000000";
          }


          const amountToSend = BigNumber.from(
            tradeHelper.trade
              .maximumAmountIn(options.slippageTolerance)
              .quotient.toString()
          );

          // For exact output trades, we need to increase the amount by 2% to account for the fee
          const adjustedAmountToSend = trade.tradeType === TradeType.EXACT_OUTPUT
            ? amountToSend.mul(102).div(100)  // Add 2% fee
            : amountToSend;

          function wrapToLSP7AuthorizeOperator(
            amount: BigNumber,
            operatorNotificationData: string
          ) {
            // function authorizeOperator(address operator, uint256 amount, bytes memory operatorNotificationData) external;
            const lsp7ContractInterface = new ethers.utils.Interface(lsp7Abi);
            const calldata = lsp7ContractInterface.encodeFunctionData(
              "authorizeOperator",
              [
                universalRouterAddressHelper(chainId as number),
                amount,
                operatorNotificationData,
              ]
            );
            return calldata;
          }

          if (isNativeToken) {
            // remove the first 4 bytes of data
            data = '0x' + data.slice(10);

            // abi decode data execute(bytes,bytes[],uint256)
            const decodedData = ethers.utils.defaultAbiCoder.decode(["bytes", "bytes[]", "uint256"], data);

            let encodedData;

            try {
              // abi encode data execute(bytes,bytes[],uint256,address)
             encodedData = ethers.utils.defaultAbiCoder.encode(["bytes", "bytes[]", "uint256", "address"], [decodedData[0], decodedData[1], decodedData[2], gridOwnerAccount || '0x0000000000000000000000000000000000000000']);
            } catch (error) {
              console.log('error', error);
            }

            // add 0x79d79861 to the beginning of the data
            data = "0x79d79861" + encodedData.slice(2);

          }


          const tx = {
            from: account,
            to: isNativeToken
              ? universalRouterAddressHelper(chainId)
              : trade.routes[0].input.address,
            data: isNativeToken
              ? data
              : wrapToLSP7AuthorizeOperator(adjustedAmountToSend, data),
            // TODO(https://github.com/Uniswap/universal-router-sdk/issues/113): universal-router-sdk returns a non-hexlified value.
            ...(value && !isZero(value) ? { value: toHex(value) } : {}),
          };

          let gasEstimate: BigNumber;
          try {
            gasEstimate = await provider.estimateGas(tx);
          } catch (gasError) {
            setTraceStatus("failed_precondition");
            setTraceError(gasError);
            sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
              ...formatCommonPropertiesForTrade(
                trade,
                options.slippageTolerance
              ),
              ...analyticsContext,
              client_block_number: blockNumber,
              tx,
              isAutoSlippage,
            });
            console.warn(gasError);
            throw new GasEstimationError();
          }
          const gasLimit = calculateGasMargin(gasEstimate);
          // setTraceData("gasLimit", gasLimit.toNumber());
          const beforeSign = Date.now();
          const response = await provider
            .getSigner()
            .sendTransaction({ ...tx, gasLimit: 10_000_000 })
            .then((response) => {
              sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
                ...formatSwapSignedAnalyticsEventProperties({
                  trade,
                  timeToSignSinceRequestMs: Date.now() - beforeSign,
                  allowedSlippage: options.slippageTolerance,
                  fiatValues,
                  txHash: response.hash,
                  portfolioBalanceUsd,
                }),
                ...analyticsContext,
                // TODO (WEB-2993): remove these after debugging missing user properties.
                [CustomUserProperties.WALLET_ADDRESS]: account,
                [CustomUserProperties.WALLET_TYPE]:
                  getConnection(connector).getProviderInfo().name,
                [CustomUserProperties.PEER_WALLET_AGENT]: provider
                  ? getWalletMeta(provider)?.agent
                  : undefined,
              });
              if (tx.data !== response.data) {
                sendAnalyticsEvent(SwapEventName.SWAP_MODIFIED_IN_WALLET, {
                  txHash: response.hash,
                  ...analyticsContext,
                });

                if (
                  !response.data ||
                  response.data.length === 0 ||
                  response.data === "0x"
                ) {
                  throw new ModifiedSwapError();
                }
              }
              return response;
            });
          return {
            type: TradeFillType.Classic as const,
            response,
          };
        } catch (swapError: unknown) {
          if (swapError instanceof ModifiedSwapError) throw swapError;

          // GasEstimationErrors are already traced when they are thrown.
          if (!(swapError instanceof GasEstimationError))
            setTraceError(swapError);

          // Cancellations are not failures, and must be accounted for as 'cancelled'.
          if (didUserReject(swapError)) {
            setTraceStatus("cancelled");
            // This error type allows us to distinguish between user rejections and other errors later too.
            throw new UserRejectedRequestError(
              swapErrorToUserReadableMessage(swapError)
            );
          }

          throw new Error(swapErrorToUserReadableMessage(swapError));
        }
      }
    );
  }, [
    account,
    chainId,
    provider,
    trade,
    options.slippageTolerance,
    options.deadline,
    options.permit,
    options.feeOptions,
    options.flatFeeOptions,
    analyticsContext,
    blockNumber,
    isAutoSlippage,
    fiatValues,
    portfolioBalanceUsd,
    connector,
  ]);
}
