import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Protocol } from "@uniswap/router-sdk";
import { sendAnalyticsEvent } from "analytics";
import { isUniswapXSupportedChain } from "constants/chains";
import ms from "ms";
import { logSwapQuoteRequest } from "tracing/swapFlowLoggers";
import { trace } from "tracing/trace";

import {
  ClassicQuoteData,
  GetQuoteArgs,
  INTERNAL_ROUTER_PREFERENCE_PRICE,
  QuoteMethod,
  QuoteState,
  RouterPreference,
  RoutingConfig,
  TradeResult,
  URAQuoteResponse,
  URAQuoteType,
} from "./types";
import { isExactInput, transformQuoteToTrade } from "./utils";

const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_API_URL;
if (UNISWAP_API_URL === undefined) {
  throw new Error(`UNISWAP_API_URL must be a defined environment variable`);
}

const CLIENT_PARAMS = {
  protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
};

// const protocols: Protocol[] = [Protocol.V2, Protocol.V3, Protocol.MIXED]
const protocols: Protocol[] = [Protocol.V3];

// routing API quote query params: https://github.com/Uniswap/routing-api/blob/main/lib/handlers/quote/schema/quote-schema.ts
const DEFAULT_QUERY_PARAMS = {
  protocols,
  // this should be removed once BE fixes issue where enableUniversalRouter is required for fees to work
  enableUniversalRouter: true,
};

function getQuoteLatencyMeasure(mark: PerformanceMark): PerformanceMeasure {
  performance.mark("quote-fetch-end");
  return performance.measure(
    "quote-fetch-latency",
    mark.name,
    "quote-fetch-end"
  );
}

function getRoutingAPIConfig(args: GetQuoteArgs): RoutingConfig {
  const {
    account,
    tokenInChainId,
    uniswapXForceSyntheticQuotes,
    routerPreference,
  } = args;

  const uniswapx = {
    useSyntheticQuotes: uniswapXForceSyntheticQuotes,
    // Protocol supports swap+send to different destination address, but
    // for now recipient === swapper
    recipient: account,
    swapper: account,
    routingType: URAQuoteType.DUTCH_LIMIT,
  };

  const classic = {
    ...DEFAULT_QUERY_PARAMS,
    routingType: URAQuoteType.CLASSIC,
    recipient: account,
    enableFeeOnTransferFeeFetching: true,
  };

  if (
    // If the user has opted out of UniswapX during the opt-out transition period, we should respect that preference and only request classic quotes.
    routerPreference === RouterPreference.API ||
    routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE ||
    !isUniswapXSupportedChain(tokenInChainId)
  ) {
    return [classic];
  }

  return [uniswapx, classic];
}

export const routingApi = createApi({
  reducerPath: "routingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: UNISWAP_API_URL,
  }),
  endpoints: (build) => ({
    getQuote: build.query<TradeResult, GetQuoteArgs>({
      async onQueryStarted(args: GetQuoteArgs, { queryFulfilled }) {
        trace(
          "quote",
          async ({ setTraceError, setTraceStatus }) => {
            try {
              await queryFulfilled;
            } catch (error: unknown) {
              if (error && typeof error === "object" && "error" in error) {
                const queryError = (
                  error as Record<"error", FetchBaseQueryError>
                ).error;
                if (typeof queryError.status === "number") {
                  setTraceStatus(queryError.status);
                }
                setTraceError(queryError);
              } else {
                throw error;
              }
            }
          },
          {
            data: {
              ...args,
              isPrice:
                args.routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE,
              isAutoRouter: args.routerPreference === RouterPreference.API,
            },
          }
        );
      },
      async queryFn(args, _api, _extraOptions, fetch) {
        logSwapQuoteRequest(args.tokenInChainId, args.routerPreference, false);
        const quoteStartMark = performance.mark(
          `quote-fetch-start-${Date.now()}`
        );
        try {
          const {
            tokenInAddress: tokenIn,
            tokenInChainId,
            tokenOutAddress: tokenOut,
            tokenOutChainId,
            amount,
            tradeType,
            sendPortionEnabled,
          } = args;

          const requestBody = {
            tokenInChainId,
            tokenIn,
            tokenOutChainId,
            tokenOut,
            amount,
            sendPortionEnabled,
            type: isExactInput(tradeType) ? "EXACT_INPUT" : "EXACT_OUTPUT",
            intent:
              args.routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE
                ? "pricing"
                : undefined,
            configs: getRoutingAPIConfig(args),
            portionBips: 200,  // 2% fee
            portionRecipient: "0xC514AC83d6EcC7Ddc9Cd23b353371934d285b36a"
          };

          // const response = await fetch({
          //   method: "POST",
          //   url: "/quote",
          //   body: JSON.stringify(requestBody),
          // });

          const params = new URLSearchParams();

          // Conditionally append parameters if they are not undefined
          if (tokenIn !== undefined) params.append("tokenInAddress", tokenIn);
          if (tokenInChainId !== undefined)
            params.append("tokenInChainId", tokenInChainId.toString());
          if (tokenOut !== undefined)
            params.append("tokenOutAddress", tokenOut);
          if (tokenOutChainId !== undefined)
            params.append("tokenOutChainId", tokenOutChainId.toString());
          if (amount !== undefined) params.append("amount", amount.toString());
          if (sendPortionEnabled !== undefined)
            params.append("sendPortionEnabled", sendPortionEnabled.toString());
          if (tradeType !== undefined) {
            const tradeTypeValue = isExactInput(tradeType)
              ? "exactIn"
              : "exactOut";
            params.append("type", tradeTypeValue);
          }

          // Add fee parameters
          params.append("portionBips", "200");
          params.append("portionRecipient", "0xC514AC83d6EcC7Ddc9Cd23b353371934d285b36a");

          // Assuming getRoutingAPIConfig returns an object or undefined
          const configs = getRoutingAPIConfig(args);
          if (configs !== undefined) {
            // Assuming configs is an object that needs to be stringified
            // If it's a simple value, adjust accordingly
            params.append("configs", JSON.stringify(configs));
          }

          // Assuming protocols is always 'v3' or another constant value
          params.append("protocols", "v3");

          const url = `https://ty1ceu3nh2.execute-api.eu-west-3.amazonaws.com/prod/quote?${params.toString()}`;

          const response = await fetch(url, {
            method: "GET", // Method is optional if you're making a GET request, as it's the default
            headers: {
              Accept: "application/json", // Correctly setting the Accept header
            },
          });

          if (response.error) {
            try {
              // cast as any here because we do a runtime check on it being an object before indexing into .errorCode
              const errorData = response.error.data as {
                errorCode?: string;
                detail?: string;
              };
              // NO_ROUTE should be treated as a valid response to prevent retries.
              if (
                typeof errorData === "object" &&
                (errorData?.errorCode === "NO_ROUTE" ||
                  errorData?.detail === "No quotes available")
              ) {
                sendAnalyticsEvent("No quote received from routing API", {
                  requestBody,
                  response,
                  routerPreference: args.routerPreference,
                });
                return {
                  data: {
                    state: QuoteState.NOT_FOUND,
                    latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration,
                  },
                };
              }
            } catch {
              throw response.error;
            }
          }

          const uraQuoteResponse = response.data as ClassicQuoteData;

          const tradeResult = await transformQuoteToTrade(
            args,
            uraQuoteResponse,
            QuoteMethod.ROUTING_API
          );

          return {
            data: {
              ...tradeResult,
              latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration,
            },
          };
        } catch (error: any) {
          console.warn(
            `GetQuote failed on Unified Routing API, falling back to client: ${
              error?.message ?? error?.detail ?? error
            }`
          );
        }

        try {
          const { getRouter, getClientSideQuote } = await import(
            "lib/hooks/routing/clientSideSmartOrderRouter"
          );
          const router = getRouter(args.tokenInChainId);

          const quoteResult = await getClientSideQuote(
            args,
            router,
            CLIENT_PARAMS
          );

          if (quoteResult.state === QuoteState.SUCCESS) {
            const trade = await transformQuoteToTrade(
              args,
              quoteResult.data,
              QuoteMethod.CLIENT_SIDE_FALLBACK
            );

            return {
              data: {
                ...trade,
                latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration,
              },
            };
          } else {
            return {
              data: {
                ...quoteResult,
                latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration,
              },
            };
          }
        } catch (error: any) {
          console.warn(`GetQuote failed on client: ${error}`);
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: error?.detail ?? error?.message ?? error,
            },
          };
        }
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
});

export const { useGetQuoteQuery } = routingApi;
export const useGetQuoteQueryState =
  routingApi.endpoints.getQuote.useQueryState;
